/* eslint-disable */
const SegmentInit = function () {
    var analytics: any = window.analytics = window.analytics || [];
    if (!analytics.initialize) if (!analytics.invoked) {
        analytics.invoked = !0;
        analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on"];
        analytics.factory = function (t: any) {
            return function () {
                var e = Array.prototype.slice.call(arguments);
                e.unshift(t);
                analytics.push(e);
                return analytics
            }
        };
        for (var t = 0; t < analytics.methods.length; t++) {
            var e = analytics.methods[t];
            analytics[e] = analytics.factory(e)
        }
        analytics.load = function (t: any, e: any) {
            var n = document.createElement("script");
            n.type = "text/javascript";
            n.async = !0;
            n.src = ("https:" === document.location.protocol ? "https://" : "http://") + "cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js";
            var o: any = document.getElementsByTagName("script")[0];
            o.parentNode.insertBefore(n, o);
            analytics._loadOptions = e
        };
        analytics.SNIPPET_VERSION = "4.1.0";
    }
};

export default SegmentInit;
