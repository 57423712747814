import ConsentService from './ConsentService';
import ConsentServiceFactory from './ConsentServiceFactory';
import LocalConsentService from './LocalConsentService';
import type Product from './Product';
import RemoteConsentService from './RemoteConsentService';
import WrappingConsentService from './WrappingConsentService';
import SegmentInit from './SegmentInit';
import Tracker from './Tracker';
import * as consentMethods from './consentMethods';

export {
    consentMethods,
    ConsentService,
    ConsentServiceFactory,
    LocalConsentService,
    RemoteConsentService,
    SegmentInit,
    Tracker,
    WrappingConsentService,
};

export type { Product };
