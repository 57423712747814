import Cookies from 'js-cookie';
import type Tracker from './Tracker';
import type ConsentService from './ConsentService';
import type { ConsentData } from './buildConsentData';
import buildConsentData from './buildConsentData';

export default class LocalConsentService implements ConsentService {
    public static readonly PAGE_TYPE_COOKIE = 'Cookie settings';

    public static readonly PAGE_TYPE_POPUP = 'Popup';

    private readonly CONSENT_DURATION_DAYS = 365;

    private readonly Cookies = Cookies;

    public constructor(
        private tracker: Tracker,
    ) {
        this.tracker = tracker;
    }

    public async wasConsentGranted(): Promise<boolean> {
        return this.getConsentData()?.trackingAllowed === true;
    }

    public async wasConsentDenied(): Promise<boolean> {
        return this.getConsentData()?.trackingAllowed === false;
    }

    public async wasConsentSpecified(): Promise<boolean> {
        return this.getConsentData() !== null;
    }

    /**
     * Returns whether the user still needs to see the analytics information
     */
    public async shouldShowInformation(pageType: string): Promise<boolean> {
        // If consent has been granted or denied no information needs to be shown
        // Also don't trigger the banner inside of popups and on the cookie page
        return this.hasConsentData() === false
            && pageType !== LocalConsentService.PAGE_TYPE_POPUP
            && pageType !== LocalConsentService.PAGE_TYPE_COOKIE;
    }

    public registerConsentGranted(method: string, circumstance: string): void {
        this.registerConsentStatus(true, method, circumstance);
    }

    public registerConsentDenied(method: string, circumstance: string): void {
        this.registerConsentStatus(false, method, circumstance);
    }

    public registerConsentData(data: ConsentData) {
        this.registerConsentStatus(
            data.trackingAllowed,
            data.method,
            data.circumstance,
            data.time,
        );
    }

    private registerConsentStatus(
        allowTracking: boolean,
        method: string,
        circumstance: string,
        time?: string,
    ): void {
        const consentData = buildConsentData(allowTracking, method, circumstance);
        if (time !== undefined) {
            consentData.time = time;
        }

        this.Cookies.set('analytics_consent', JSON.stringify(consentData), { expires: this.CONSENT_DURATION_DAYS });
    }

    private getConsentData(): ConsentData|null {
        let consentData;
        try {
            consentData = this.Cookies.get('analytics_consent');
            if (typeof consentData !== 'string') {
                return null;
            }
            consentData = JSON.parse(consentData) as ConsentData|null;
        } catch (e) {
            consentData = null;
        }

        if (typeof consentData !== 'object') {
            consentData = null;
        }

        return consentData;
    }

    public hasConsentData(): boolean {
        const consentData = this.getConsentData();

        return consentData !== null;
    }
}
