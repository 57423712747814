const buildConsentData = (
    allowTracking: boolean,
    method: string,
    circumstance: string,
): ConsentData => ({
    circumstance,
    method,
    time: new Date().toISOString(),
    trackingAllowed: Boolean(allowTracking),
});

export default buildConsentData;

export interface ConsentData {
    circumstance: string;
    method: string;
    time: string;
    trackingAllowed: boolean;
}
