import type ConsentService from './ConsentService';
import type LocalConsentService from './LocalConsentService';
import type RemoteConsentService from './RemoteConsentService';

export default class WrappingConsentService implements ConsentService {
    public constructor(
        private local: LocalConsentService,
        private remote: RemoteConsentService,
    ) {
    }

    public registerConsentDenied(method: string, circumstance: string): void {
        this.local.registerConsentDenied(method, circumstance);
        this.remote.registerConsentDenied(method, circumstance);
    }

    public registerConsentGranted(method: string, circumstance: string): void {
        this.local.registerConsentGranted(method, circumstance);
        this.remote.registerConsentGranted(method, circumstance);
    }

    public async shouldShowInformation(pageType: string): Promise<boolean> {
        if (this.local.hasConsentData()) {
            return this.local.shouldShowInformation(pageType);
        }

        const shouldShow = await this.remote.shouldShowInformation(pageType);

        this.updateLocalConsent();

        return shouldShow;
    }

    public async wasConsentDenied(): Promise<boolean> {
        if (this.local.hasConsentData()) {
            return this.local.wasConsentDenied();
        }

        return this.remote.wasConsentDenied();
    }

    public async wasConsentGranted(): Promise<boolean> {
        if (this.local.hasConsentData()) {
            return this.local.wasConsentGranted();
        }

        return this.remote.wasConsentGranted();
    }

    public async wasConsentSpecified(): Promise<boolean> {
        if (await this.local.wasConsentSpecified()) {
            return true;
        }
        const wasSpecified = await this.remote.wasConsentSpecified();

        if (wasSpecified) {
            this.updateLocalConsent();
        }

        return wasSpecified;
    }

    private updateLocalConsent(): void {
        const result = this.remote.getResolvedConsentData();
        if (result) {
            this.local.registerConsentData(result);
        }
    }
}
