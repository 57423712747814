import type ConsentService from './ConsentService';
import type { ConsentData } from './buildConsentData';
import buildConsentData from './buildConsentData';
import LocalConsentService from './LocalConsentService';
import type { Fetch } from './Fetch';
import { window } from './globals';

export default class RemoteConsentService implements ConsentService {
    private consentData: ConsentData|null|undefined;

    private readonly fetch: Fetch | undefined;

    public constructor(
        private readonly endpoint: string,
        fetch?: Fetch,
    ) {
        this.fetch = fetch || window?.fetch;
    }

    public registerConsentDenied(method: string, circumstance: string): void {
        this.registerConsentStatus(false, method, circumstance);
    }

    public registerConsentGranted(method: string, circumstance: string): void {
        this.registerConsentStatus(true, method, circumstance);
    }

    private registerConsentStatus(
        allowTracking: boolean,
        method: string,
        circumstance: string,
    ): void {
        if (!this.fetch) {
            return;
        }

        const consentData = buildConsentData(allowTracking, method, circumstance);

        let event = 'Tracking Consent Denied';
        if (allowTracking) {
            event = 'Tracking Consent Granted';
        }

        type Properties = {
            circumstance: string;
            label: string;
            method: string;
            nonInteraction?: number;
        };

        const properties: Properties = {
            circumstance: consentData.circumstance,
            label: consentData.circumstance,
            method: consentData.method,
            nonInteraction: 1,
        };

        const query = Object.keys(properties)
            .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(properties[k as keyof Properties] as string)}`)
            .join('&');

        this.fetch(`${this.endpoint}/register?${query}&event=${encodeURIComponent(event)}`, {
            keepalive: true,
            method: 'POST',
        }).catch(() => {
            console.warn('unable to register consent status');
        });
    }

    public async shouldShowInformation(pageType: string): Promise<boolean> {
        return (await this.getConsentData()) === null
            && pageType !== LocalConsentService.PAGE_TYPE_POPUP
            && pageType !== LocalConsentService.PAGE_TYPE_COOKIE;
    }

    public async wasConsentDenied(): Promise<boolean> {
        const data = await this.getConsentData();
        return !!data && !data.trackingAllowed;
    }

    public async wasConsentGranted(): Promise<boolean> {
        const data = await this.getConsentData();
        return !!data && data.trackingAllowed;
    }

    public async wasConsentSpecified(): Promise<boolean> {
        return (await this.getConsentData()) !== null;
    }

    public getResolvedConsentData(): ConsentData|null {
        if (this.consentData === undefined) {
            throw new Error('ConsentData not specified yet');
        }
        return this.consentData;
    }

    public async getConsentData(): Promise<ConsentData|null> {
        if (this.consentData !== undefined) {
            return this.consentData;
        }

        if (!this.fetch) {
            return null;
        }

        const response = await this.fetch(`${this.endpoint}/fetch`);
        const result: unknown = await response.json();

        if (RemoteConsentService.isConsentData(result)) {
            this.consentData = result;
            return result;
        }

        this.consentData = null;
        return null;
    }

    private static isConsentData(data: unknown): data is ConsentData {
        if (typeof data !== 'object' || data === null) {
            return false;
        }

        return Object.prototype.hasOwnProperty.call(data, 'trackingAllowed')
            && Object.prototype.hasOwnProperty.call(data, 'method')
            && Object.prototype.hasOwnProperty.call(data, 'circumstance')
            && Object.prototype.hasOwnProperty.call(data, 'time');
    }
}
