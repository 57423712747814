import type Product from './Product';
import { window } from './globals';

/**
 * This class acts as a wrapper for Segment's analytics.js
 * for easier use, consistent event names and autocompletion
 *
 * Because Segment replaces the reference to analytics once it is loaded
 * it cannot be injected through the constructor
 * and all calls are made to the global analytics object
 */

export default class Tracker {
    private readonly EVENT_PRODUCT_LIST_VIEWED = 'Product List Viewed';

    private readonly EVENT_PRODUCT_LIST_FILTERED = 'Product List Filtered';

    private readonly EVENT_PRODUCT_CLICKED = 'Product Clicked';

    private readonly EVENT_PRODUCT_VIEWED = 'Product Viewed';

    private readonly EVENT_PRODUCTS_SEARCHED = 'Products Searched';

    public currentSettings?: string;

    public load(apiKey: string, loadOptions?: Record<string, unknown>) {
        const serializedSettings = JSON.stringify({ apiKey, loadOptions });
        if (this.currentSettings && this.currentSettings !== serializedSettings) {
            console.warn('Attempting to load analytics.js a second time but with different settings. No load attempted.');
        } else {
            this.currentSettings = serializedSettings;
        }

        // Segment will remove this method from the analytics object
        // once it is loaded
        if (typeof window?.analytics?.load === 'function') {
            analytics.load(apiKey, loadOptions);

            // Disable the load function after the first attempt
            // to prevent double loading if it is called more than once before loading completes
            analytics.load = () => {};
        }
    }

    /**
     * Only load Google Analytics that is configured
     * to not track personally identifiable information
     */
    public loadWithoutTrackingPii(apiKey: string): void {
        const loadOptions = { integrations: { All: false, 'Google Analytics': true } };

        this.load(apiKey, loadOptions);
    }

    public onReady(callback: () => void): void {
        window?.analytics?.ready(callback);
    }

    public identify(userId: string, traits?: Record<string, unknown>, options?: Record<string, unknown>, callback?: () => void) {
        window?.analytics?.identify(userId, traits, Tracker.buildOptions(options), callback);
    }

    public reset(): void {
        window?.analytics?.reset();
    }

    public page(pageData?: Record<string, unknown>, options?: Record<string, unknown>) {
        window?.analytics?.page(pageData, Tracker.buildOptions(options));
    }

    public track(event: string, properties?: object, options?: SegmentAnalytics.SegmentOpts, callback?: () => void) {
        window?.analytics?.track(event, properties, Tracker.buildOptions(options), callback);
    }

    private static buildOptions(options?: SegmentAnalytics.SegmentOpts): SegmentAnalytics.SegmentOpts {
        // Segment should not be loaded without consent, as such we can grant consent to everything here
        const consentOptions = {
            consent: {
                categoryPreferences: {
                    Advertising: true,
                    Analytics: true,
                    DataSharing: true,
                    Functional: true,
                },
            },
        };

        return {
            ...options,
            context: {
                ...options?.context,
                ...consentOptions,
            },
        };
    }

    /**
     * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#emitter
     */
    public on(
        method: string,
        callback: (
            event: string,
            properties: Object,
            options: SegmentAnalytics.SegmentOpts
        ) => void,
    ) {
        window?.analytics?.on(method, callback);
    }

    public trackProductListViewed(
        listName: string,
        products: Product[],
        additionalData: Record<string, unknown> = {},
    ) {
        Tracker.getProductListSlices(products).forEach((productsSlice) => {
            this.track(this.EVENT_PRODUCT_LIST_VIEWED, {
                ...additionalData,
                category: '',
                list_id: listName,
                products: productsSlice,
            });
        });
    }

    public trackProductListFiltered(
        listName: string,
        filters: unknown[],
        sorts: unknown[],
        products: Product[],
        additionalData: Record<string, unknown> = {},
    ): void {
        Tracker.getProductListSlices(products).forEach((productsSlice) => {
            this.track(this.EVENT_PRODUCT_LIST_FILTERED, {
                ...additionalData,
                category: '',
                filters,
                list_id: listName,
                products: productsSlice,
                sorts,
            });
        });
    }

    public trackProductClicked(product: Product): void {
        this.track(this.EVENT_PRODUCT_CLICKED, product);
    }

    public trackProductViewed(product: Product): void {
        this.track(this.EVENT_PRODUCT_VIEWED, product);
    }

    public trackProductsSearched(query: string): void {
        this.track(this.EVENT_PRODUCTS_SEARCHED, {
            query,
        });
    }

    public trackLink(link: HTMLElement, eventName: string, properties: Record<string, unknown>) {
        window?.analytics?.trackLink(link, eventName, properties);
    }

    public getAnonymousId(): string|null {
        const anonymousId = window?.analytics?.user().anonymousId();
        if (typeof anonymousId !== 'string' || !anonymousId) {
            return null;
        }

        return anonymousId;
    }

    public getUserId(): string|null {
        const userId = window?.analytics?.user().id();
        if (typeof userId !== 'string' || !userId) {
            return null;
        }

        return userId;
    }

    public createProduct(
        id: number,
        name: string,
        price: number,
        currency: string,
        imageUrl?: string,
        quantity?: number,
        url?: string,
        position?: number,
        combinedProductId?: number,
    ): Product {
        const product: Product = {
            currency,
            name,
            price,
            productId: id,
        };

        if (typeof imageUrl === 'string') {
            product.image = imageUrl;
        }

        if (typeof quantity === 'number') {
            product.quantity = quantity;
        }

        if (typeof url === 'string') {
            product.url = url;
        }

        if (typeof position === 'number') {
            product.position = position;
        }

        if (combinedProductId) {
            product.combinedProductId = combinedProductId;
        }

        return product;
    }

    public createFilter(attribute: string, value: string): {type: string; value: string} {
        return {
            type: attribute,
            value,
        };
    }

    public createSort(type: string, ascending: boolean): {type: string; value: string} {
        return {
            type,
            value: ascending ? 'asc' : 'desc',
        };
    }

    private static getProductListSlices(
        products: Product[],
        maxListSize: number = 50,
    ): Product[][] {
        const slices = [];
        // To prevent the hits exceeding the maximum payload size we split the product list
        for (let i = 0; i < products.length; i += maxListSize) {
            slices.push(products.slice(i, i + maxListSize));
        }

        return slices;
    }
}
